import React, { useState, useEffect } from 'react';
import { globalHistory } from '@reach/router'
import { useStaticQuery, Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { format } from 'date-fns'
import Moon from "../assets/icons/moon.svg";
import Sun from "../assets/icons/sun.svg";
import Twitter from "../assets/icons/twitter.svg";
import LinkedIn from "../assets/icons/linkedin-in.svg";
import Mail from "../assets/icons/at.svg";
import "../styles/app.scss"

export default function Layout({ children }) {

  const [darkMode, setDarkmode] = useState(typeof window !== 'undefined' ? localStorage.getItem('darkMode') === 'true' : false);
    const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true);
  }, [])

  const updateDarkMode = () => {
    localStorage.setItem('darkMode', !darkMode ? 'true' : 'false');
    setDarkmode(!darkMode);
  };

  const pathname = typeof window !== 'undefined' ? globalHistory.location.pathname : null;

  const data = useStaticQuery(graphql`
  query {
    blog: allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {type: {eq: "blog"}}}, limit: 4) {
      edges {
        node {
          id
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    },
    tweets: allTwitterStatusesUserTimelineTimeline(limit: 3, sort: {fields: id_str, order: DESC}) {
      edges {
        node {
          id
          id_str
          full_text
          created_at
        }
      }
    }

  }
`)

const key = isClient ? 'client' : 'server';
    
  return <div className={`app ${darkMode ? 'dark' : ''}`} key={key}>
      
            <Helmet>
            <meta charSet="utf-8" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link rel="preconnect" href="https://www.google-analytics.com" crossorigin />
            <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400|Open+Sans:400,600,600i|Inconsolata&display=swap" rel="stylesheet" />
            </Helmet>

            <a href="#main" className="visually-hidden">Skip to main content</a>

            <header className="container" role="banner">
                <Link to="/" className="logo _animate"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.2 425.2"><polygon className="logo__left" points="0,0 0,425.2 219.2,425.2 59.8,265.9 6.8,212.9 59.8,159.9 219.5,0 "/><polygon className="logo__middle" points="325.6,0 112.8,212.8 325.2,425.2 424.9,425.2 265.5,265.9 212.5,212.9 265.5,159.9 425.2,0.1 425.2,0"/><polygon className="logo__right" points="425.2,106.2 318.6,212.8 425.2,319.4 "/></svg> Kevin Choppin</Link>
                <button className="mode-switch" onClick={updateDarkMode}><Sun /><Moon /></button>
                <nav aria-label="Main Navigation">
                    <ul>
                        <li className={pathname === "/" ? "active" : ""}><Link to="/">Home</Link></li>
                        <li className={pathname?.startsWith("/blog") ? "active" : ""}><Link to="/blog">Blog</Link></li>
                    </ul>
                </nav>
            </header>

            <main id="main">
                {children}
            </main>

            <footer>
                
                <div className="container">
                
                    <div className="footer-col">
                        <h2>Contact Info</h2>
                        <ul>
                            <li><a href="mailto:me@kevinchoppin.dev" aria-label="Send an email to me@kevinchoppin.dev"><Mail /> me@kevinchoppin.dev</a></li>
                            <li><a href="https://linkedin.com/in/kevinchoppin" target="_blank" rel="nofollow noopener noreferrer" aria-label="View my LinkedIn Profile"><LinkedIn /> linkedin.com/in/kevinchoppin</a></li>
                            <li><a href="https://twitter.com/kevchoppin" target="_blank" rel="nofollow noopener noreferrer" aria-label="View my Twitter Profile @kevchoppin"><Twitter /> @kevchoppin</a></li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h2>Latest Tweets <a href="https://twitter.com/kevchoppin" target="_blank" rel="nofollow noopener noreferrer" aria-label="View my Twitter Profile"><Twitter /></a></h2>
                        <ul className="twitter-feed">
                          {data.tweets.edges.map(({ node }) => (
                            <li key={node.id}><a href={ `https://twitter.com/kevchoppin/status/${node.id_str}` } target="_blank" rel="nofollow noopener noreferrer">{ node.full_text }</a> <time dateTime={node.created_at}>{format(new Date(node.created_at), 'do MMMM, yyyy')}</time></li>
                          ))}                            
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h2>Latest Blog Posts</h2>
                        <ul>
                        {data.blog.edges.map(({ node }) => (
                            <li key={node.id}><Link to={'/blog'+node.fields.slug}>{node.frontmatter.title}</Link> <time dateTime={node.frontmatter.date}>{format(new Date(node.frontmatter.date), 'do MMMM, yyyy')}</time></li>
                        ))}
                        </ul>
                    </div>
                    
                </div>
                
                <p className="copyright container">&copy;{ new Date().getFullYear() } Kevin Choppin</p>
                
            </footer>

        </div>
}